import "./App.css";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import {
  FaHome,
  FaTruckMoving,
  FaTachometerAlt,
  FaAddressBook,
} from "react-icons/fa"; // Choose the icons you like
import GeneralOrders from "./components/GeneralOrders";
import { useState, useEffect } from "react";
import Home from "./components/Home";
import TrackOrder from "./components/TrackOrder";
import Modal from 'react-modal';

Modal.setAppElement('#root');

function App() {
  const [user, setUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [option, setOption] = useState("home");

  useEffect(() => {
    // Set up an observer to check for authentication changes
    const unsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
      setUser(authUser);
      if (!authUser) {
        // If user is not authenticated, show login prompt
        setModalIsOpen(true);
      }
    });

    // Clean up the observer on component unmount
    return () => unsubscribe();
  }, []);

  const handleLogin = async () => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
      setModalIsOpen(false);
    } catch (error) {
      console.error('Error during login:', error.message);
      alert(error.message);
    }
  };

  const handleLogout = async () => {
    try {
      // Sign out the user
      await firebase.auth().signOut();
      setModalIsOpen(true);
    } catch (error) {
      console.error('Error during logout:', error.message);
      alert(error.message);
    }
  };

  const modalCustomStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      boxShadow: "0px 0px 10px 2px darkgrey",
    },
  };

  return (
    <div>
      {
        user ? (<div className="p-1 w-full bg-slate-100 h-screen" >
          <div className="grid  grid-cols-10 h-screen">
            <div className="grid col-span-1 border-r-2 ">
              <button
                onClick={() => setOption("home")}
                className="flex items-center space-x-2 p-4"
              >
                <span className="text-xl font-bold hover:text-slate-400 mx-auto">
                  T A Z Z U
                </span>
              </button>
              <button
                onClick={() => setOption("dashboard")}
                className="flex items-center space-x-2 hover:text-slate-400 p-4"
              >
                <FaTruckMoving className="h-9 w-9 mx-auto" />
              </button>
              <button
                onClick={() => setOption("trackOrder")}
                className="flex items-center space-x-2 p-4"
              >
                <FaAddressBook className="h-9 w-9 mx-auto hover:text-slate-400 " />
              </button>
              <button
                onClick={handleLogout}
                className="text-center font-semibold items-center p-4"
              >
                Logout
              </button>
            </div>
            <div className="col-span-9">
              {option == "home" && <Home></Home>}
              {option == "dashboard" && <GeneralOrders></GeneralOrders>}

              {option == "trackOrder" && <TrackOrder></TrackOrder>}
            </div>
          </div>
        </div>) : (
          <div className="w-full bg-slate-500 h-screen">
            <Modal isOpen={modalIsOpen} style={modalCustomStyles}>
              <img src="favicon.jpg" width={100} className="self-center mb-8"></img>
              <label className="flex justify-between">
                Email:
                <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className="ml-2 mb-4 border border-black border-1 p-1 w-[300px]" />
              </label>
              <label className="flex justify-between">
                Password:
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="ml-2 mb-4 border border-black border-1 p-1 w-[300px]"
                />
              </label>
              <button className="mt-2 bg-black text-white p-1 w-1/2 self-center" onClick={handleLogin}>Login</button>
            </Modal>
          </div>
        )
      }
    </div>
  );
}

export default App;
